import React from 'react';

import SiteLayout from '@layouts/site-layout';

export default class InfoPage extends React.Component {
    state = {
        amount: 100,
    };

    paymentHandler = async (response) => {
        console.log({ response });
        const paymentId = response.razorpay_payment_id;

        const url = `${process.env.GATSBY_API_URL}/api/v1/rzp_capture/${paymentId}/${this.state.amount * 100}`;

        let resp = await fetch(url, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        });
        resp = resp.json();
        console.log({ resp });
    }

    openCheckout = () => {
        const options = {
            "key": process.env.GATSBY_RAZORPAY_API_KEY,
            "amount": this.state.amount * 100, // 2000 paise = INR 20, amount in paisa
            "name": "STEPapp",
            "description": "Purchase Description",
            "image": "https://stepapp.ai/img/stepapp_logo.png",
            "handler": this.paymentHandler,
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }

    render() {
        return (
            <SiteLayout className="text-center">
                <h1 className="has-text-weight-bold is-size-1">Payment</h1>
                <div className="mt-5 mb-5">
                    <button className="btn btn-primary" onClick={this.openCheckout}>Pay Rs. {this.state.amount}</button>
                </div>
            </SiteLayout>
        )
    }
}
